/* eslint-disable */
import React from 'react'
import { graphql, navigate } from 'gatsby'
import StateDropdown from '@leshen/gatsby-theme-leshen/src/components/StateDropdown'

import coupleSmiling from '../images/heroes/couple-smiling.jpg'
import coupleLaughing from '../images/people/couple-laughing.jpg'
import coupleBeachWalkingAlt from '../images/people/beach-walking-alt.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import CountdownTimer from '../components/Countdown'
import DynamicCTACard from '../components/DynamicCTACard'
import SeoCtaBar from '../components/SeoCtaBar'

import CobraBanner from '../components/CobraBanner'

import {
  Columns,
  Column,
  Hero,
  Link,
  LinkButton,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../components/Footer'
import header from '../components/Header'

const Home = ({ data }) => {
  const { rotatedNumber, requestId } = useMapi()
  const brandyList = ['disclaimer-aet-locations']

  const stateDropdownData = [
    { label: 'Alabama', value: '/locations/alabama' },
    { label: 'Alaska', value: '/locations/alaska' },
    { label: 'Arizona', value: '/locations/arizona' },
    { label: 'Arkansas', value: '/locations/arkansas' },
    { label: 'California', value: '/locations/california' },
    { label: 'Colorado', value: '/locations/colorado' },
    { label: 'Connecticut', value: '/locations/connecticut' },
    { label: 'Delaware', value: '/locations/delaware' },
    { label: 'Florida', value: '/locations/florida' },
    { label: 'Georgia', value: '/locations/georgia' },
    { label: 'Hawaii', value: '/locations/hawaii' },
    { label: 'Idaho', value: '/locations/idaho' },
    { label: 'Illinois', value: '/locations/illinois' },
    { label: 'Indiana', value: '/locations/indiana' },
    { label: 'Iowa', value: '/locations/iowa' },
    { label: 'Kansas', value: '/locations/kansas' },
    { label: 'Kentucky', value: '/locations/kentucky' },
    { label: 'Louisiana', value: '/locations/louisiana' },
    { label: 'Maine', value: '/locations/maine' },
    { label: 'Maryland', value: '/locations/maryland' },
    { label: 'Massachusetts', value: '/locations/massachusetts' },
    { label: 'Michigan', value: '/locations/michigan' },
    { label: 'Minnesota', value: '/locations/minnesota' },
    { label: 'Mississippi', value: '/locations/mississippi' },
    { label: 'Missouri', value: '/locations/missouri' },
    { label: 'Montana', value: '/locations/montana' },
    { label: 'Nebraska', value: '/locations/nebraska' },
    { label: 'Nevada', value: '/locations/nevada' },
    { label: 'New Hampshire', value: '/locations/new-hampshire' },
    { label: 'New Jersey', value: '/locations/new-jersey' },
    { label: 'New Mexico', value: '/locations/new-mexico' },
    { label: 'New York', value: '/locations/new-york' },
    { label: 'North Carolina', value: '/locations/north-carolina' },
    { label: 'North Dakota', value: '/locations/north-dakota' },
    { label: 'Ohio', value: '/locations/ohio' },
    { label: 'Oklahoma', value: '/locations/oklahoma' },
    { label: 'Oregon', value: '/locations/oregon' },
    { label: 'Pennsylvania', value: '/locations/pennsylvania' },
    { label: 'Rhode Island', value: '/locations/rhode-island' },
    { label: 'South Carolina', value: '/locations/south-carolina' },
    { label: 'South Dakota', value: '/locations/south-dakota' },
    { label: 'Tennessee', value: '/locations/tennessee' },
    { label: 'Texas', value: '/locations/texas' },
    { label: 'Utah', value: '/locations/utah' },
    { label: 'Vermont', value: '/locations/vermont' },
    { label: 'Virginia', value: '/locations/virginia' },
    { label: 'Washington', value: '/locations/washington' },
    { label: 'West Virginia', value: '/locations/west-virginia' },
    { label: 'Wisconsin', value: '/locations/wisconsin' },
    { label: 'Wyoming', value: '/locations/wyoming' },
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: 'Aetna Medicare Agents and Plans Near Me | Call 855-200-5690 ',
          description:
            'Click here to find Aetna Medicare agents and plans near you. Aetna Medicare is available in all US states. Call today to learn more 855-200-5690',
          robots: 'follow,index',
        },
        path: '/locations',
        promoCode: '120781',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Aetna Medicare Agents and Plans Near Me | Call 855-200-5690 ',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={coupleSmiling}
                  alt="elderly talks on mobile phone and smiles"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Aetna<sup>®</sup> Medicare Locations{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>
        <SeoCtaBar />
        <CountdownTimer />
        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Find the Medicare plan you need without leaving your home
              </Typography>
              <Typography variant="body">
                Did you know you don’t have to walk into an office to enroll in
                a Medicare plan? With just a simple phone call, a friendly,
                licensed agent can answer any questions you may have and help
                you find the best benefits in your area. Speak with an agent
                today to learn more.
              </Typography>
            </>
          }
        ></VariableContent>

        <SplitContent
          backgroundColor="lightGray"
          alignImageToBottom={false}
          image={
            <img
              src={coupleLaughing}
              alt="an elderly couple look at mobile phone and laugh"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Get in contact with a Medicare agent near you
              </Typography>
              <Typography variant="body">
                Looking for a licensed insurance agent to help you enroll in
                Medicare plans? Call today!
              </Typography>
              <Typography variant="body">
                No matter where you live in the U.S., our friendly, licensed
                agents are dedicated to helping you find the right plan, at the
                right price, for your budget and needs. Speak with an agent
                today, and they’ll tell you about all the plans available near
                you!
              </Typography>
            </>
          }
        />

        <CobraBanner />

        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Medicare plans available in your area
              </Typography>
              <Typography variant="body">
                Aetna offers a variety of Medicare health insurance plans to
                people all over the country. Find everything from Original
                Medicare to a Medicare Advantage plan, which gives you access to
                more benefits than Original Medicare.
              </Typography>
              <Typography variant="body">
                Plan benefits vary by location, so make sure to talk to a
                licensed agent today. Your agent can tell you what is available
                where you live.
              </Typography>
              <Typography variant="h4" color="primary">
                Medicare plans in your area may include:
              </Typography>
            </>
          }
        >
          <Stack>
            <Columns>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../images/icons/purple-hospital.svg"
                    alt="hospital icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Part A (hospital & hospice coverage)
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/parta"
                  color="primary"
                  className="margin-x-auto"
                  requestId={requestId}
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../images/icons/purple-stethoscope.svg"
                    alt="stethoscope icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Part B (medical coverage & preventative care)
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/medicare-part-b"
                  color="primary"
                  className="margin-x-auto"
                  requestId={requestId}
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../images/icons/purple-health.svg"
                    alt="health icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Part C (Medicare Advantage)
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/advantage"
                  color="primary"
                  className="margin-x-auto"
                  requestId={requestId}
                >
                  Learn More
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent backgroundColor="lightGray">
          <Stack>
            <Columns>
              <Column>
                <>
                  <Typography variant="h2">Medicare in your state</Typography>
                  <Typography variant="body">
                    Looking for more information about Medicare plans,
                    locations, or brokers in your state? You’ve come to the
                    right place! Simply select your state from the dropdown
                    menu.
                  </Typography>
                  <Typography variant="body">
                    Don’t see your state listed here? Call{' '}
                    <Link to={`tel:${rotatedNumber}`}>{rotatedNumber} </Link>
                    to speak to an agent about Medicare in your state.
                  </Typography>
                </>
              </Column>
              <Column>
                <>
                  <div className="card bg-light">
                    <Typography variant="h4" color="primary">
                      Select Your State{' '}
                    </Typography>
                    <StateDropdown
                      placeholderText="Choose Your Location"
                      options={stateDropdownData}
                      onChange={(e) => navigate(e.value)}
                      className="state-dropdown"
                      aoa={null}
                    />
                  </div>
                </>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <SplitContent
          backgroundColor="light"
          alignImageToBottom={false}
          image={
            <img
              src={coupleBeachWalkingAlt}
              alt="an elderly couple walks on beach and looks at each other"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Enroll in a Medicare Plan Today!
              </Typography>
              <Typography variant="body">
                Ready to enroll in a plan? Get started by speaking with a
                licensed agent today at{' '}
                <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link>.
              </Typography>
              <div className="card bg-light-gray">
                <Typography variant="h4">
                  Speak with a licensed agent{' '}
                </Typography>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="primary"
                  className="margin-x-auto"
                  requestId={requestId}
                >
                  Click Here
                </LinkButton>
              </div>
            </>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
